import { Route, Routes, useLocation } from 'react-router'
import MainLayout from './layout/MainLayout'
import { useEffect } from 'react'
import isMobile from 'helpers/isMobile'
import LandingPage from 'pages/landing'
import AboutUsPage from 'pages/about'
import metaPixel from 'helpers/metaPixel'

function Root() {
  const location = useLocation()

  useEffect(() => {
    if (window.location.pathname !== "/tests/svk") return document.getElementById('root')?.classList.add('loaded')
    document.getElementById('root')?.classList.add('initial-load')

    const timeout = setTimeout(() => {
      document.getElementById('root')?.classList.replace('initial-load', 'loaded')
      setTimeout(() => document.body.getAnimations({subtree: true}).forEach((anim) => anim.finish()), 0)
    }, 4000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    if (!isMobile()) return
    const styleSheets = Array.from(document.styleSheets)
    styleSheets.forEach((styleSheet) => {
      if (styleSheet.ownerNode?.nodeName === "STYLE") {
        const rules = Array.from(styleSheet.cssRules)
        rules.forEach((rule) => {
          if (rule instanceof CSSStyleRule) {
            if (rule.selectorText.includes(":hover")) {
              rule.selectorText = rule.selectorText.replace(":hover", ":active")
            }
          }
        })
      }
    })
  }, [])

  useEffect(() => {
    // @ts-ignore
    fbq('track', "PageView");
  }, [location])

  return (
    <Routes>
      <Route path="*" element={<MainLayout />}>
        <Route index element={<LandingPage />} />
        <Route path="about" element={<AboutUsPage />} />
      </Route>
    </Routes>
  )
}

export default Root
