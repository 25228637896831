import { LogoTextVertical } from 'assets'
import { FC, useMemo } from 'react'
import { useNavigate } from 'react-router'

const siteConfig = [
    {
    language: "sk",
    button: "Testuj Slovensky",
    site: "https://kuramatura.sk/tests"
},
{
    language: "cs",
    button: "Testuj Česky",
    site: "https://kuramatura.cz/tests"
}]

const LandingPage: FC = () => {
  const navigate = useNavigate()
  const languagesAvailableFiltered = useMemo(() => navigator.languages.filter(lang => siteConfig.find(site => site.language === lang)), [])

  const sitesOrdered = useMemo(() => {
    const missingLanguages = Object.values(siteConfig).map(s => s.language).filter(lang => !languagesAvailableFiltered.includes(lang))
    const presentSites = languagesAvailableFiltered.map(lang => siteConfig.find(s => s.language === lang)!)
    const missingSites = missingLanguages.map(lang => siteConfig.find(s => s.language === lang)!)

    return [...presentSites, ...missingSites]
  }, [languagesAvailableFiltered])

  return (
    <div className="w-full h-full flex flex-col items-center justify-end px-8 select-none min-h-full h-max gap-5 mobile:pt-10 mobileWidth:pt-10 py-5">
      <LogoTextVertical className="w-16 min-w-16 opacity-0 shrink-0 mobile:hidden mobileWidth:hidden" />
      <div className="max-w-[600px] mobile:max-w-full mobileWidth:max-w-full flex flex-col items-center justify-center gap-4 min-h-max h-full grow px-5">
        <h1 className="text-4xl text-kura uppercase text-center font-bold leading-[140%] pb-4 -mx-5">
          <span style={{ animation: 'fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1)', animationFillMode: 'forwards' }} className="delay-[400ms] opacity-0">
            Maturita Online
          </span>
        </h1>
        {sitesOrdered.map((config, i) => (
            <button
            key={config.language}
            style={{ animation: `fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1) ${i*0.5 + 0.5}s`, animationFillMode: 'forwards' }}
            className="button-primary opacity-0 text-2xl py-3 font-bold bg-kura text-yellow-300 px-12 w-full"
            onClick={() => window.open(config.site, "_self")}
            >
                {config.button}
            </button>
        ))}
      </div>
      <LogoTextVertical onClick={() => navigate("/about")} className="w-16 min-w-16 shrink-0 cursor-pointer" />
    </div>
  )
}

export default LandingPage
