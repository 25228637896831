import { FC } from "react";
import { Outlet } from "react-router";
import DubkovePozadie from "components/DubkovePozadie";

const MainLayout: FC = () => {
    return (
        <div className="w-screen h-full overflow-visible flex bg-back">
            <div className="h-full grow overflow-y-auto z-10 relative">
                <Outlet />
            </div>
            <DubkovePozadie />
        </div>
    )
} 

export default MainLayout;