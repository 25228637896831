import useIsMobile from 'hooks/useIsMobile'
import useMousePos from 'hooks/useMousePos'
import useScreenSize from 'hooks/useScreenSize'
import useWindowSize from 'hooks/useWindowSize'
import { FC, useEffect, useState } from 'react'

const koloryDubkove = ['rgba(253,224,71,30%)', 'rgba(253,224,71,50%)', 'rgba(249,182,114,30%)', 'rgba(249,182,114,50%)']

interface DubekProps {
  color: number
  size: number
  initialAngle: number
  angleFreedom: number
  left: number
  top: number
  mobile?: boolean
}

const Dubek: FC<DubekProps> = ({ left, top, angleFreedom, initialAngle, size: _size, color, mobile }) => {
  const [angle, setAngle] = useState(-(initialAngle + 45))
  const { w, h } = useWindowSize()
  const {w:sw, h:sh} = useScreenSize()

  const ow = mobile ? 1080 : 1200
  const oh = mobile ? 1920 : 550

  const size = mobile ? Math.max(_size, (_size * Math.min(w / sw, h / sh))) : (_size * Math.min(w / ow, h / oh))

  const { x, y } = useMousePos()
  const [angleAdd, setAngleAdd] = useState(0)

  useEffect(() => {
    const dist = Math.sqrt((x - w * left) ** 2 + (y - h * top) ** 2)
    const angleAdd = (Math.sin(dist / 100) * dist * angleFreedom) / 10
    setAngleAdd(angleAdd)
  }, [x, y, angleFreedom, left, top, h, w, mobile])

  return (
    <div
      className={['absolute border-transparent rounded-full'].asClass}
      ref={(e) => {
        if (!e) return
        e.style.setProperty('--dub-index', `${color}`)
        e.classList.add('animate-bg-dubek-in')
      }}
      style={{
        width: 0,
        height: 0,
        borderRadius: '50%',
        borderWidth: size,
        borderTopColor: koloryDubkove[color],
        transition: 'transform 0.5s',
        transform: `translate(${w * left - size}px, ${h * top - size}px) rotate(${angle + angleAdd}deg)`, // translate: `${translateX}px, ${translateY}px
        borderStyle: 'solid',
      }}
    />
  )
}

const DubkovePozadie: FC = () => {
  const mobile = useIsMobile()
  const dubkyDesktop = [
    [230, 412, -103, 2, 105],
    [110, 297, 219, 1, 13],
    [60, 262, 278, 3, 297],
    [102, 349, 327, 3, 168],
    [133, 350, 495, 0, 52],
    [59, 407, 509, 1, 294],
    [109, 734, 553, 3, 217],
    [280, 909, 590, 0, 285],
    [132, 1156, 370, 3, 22],
    [76, 952, 272, 1, 253],
    [180, 1163, 230, 2, 333],
    [110, 924, -33, 1, 150],
  ] as const

  const dubkyMobile = [
    [230, 552, -237, 2, 105],
    [110, 380, 343, 1, 13],
    [60, 10, 1076, 3, 297],
    [102, 159, 1300, 3, 168],
    [59, 272, 1697, 1, 294],
    [280, 674, 2136, 0, 285],
    [132, 1138, 1553, 3, 22],
    [76, 882, 822, 1, 253],
    [110, 1005, 111, 3, 150],
  ] as const

  const dubky = mobile ? dubkyMobile : dubkyDesktop

  const w = mobile ? 1080 : 1000
  const h = mobile ? 1920 : 550

  const translateX = mobile ? 0 : -200

  return (
    <div className="absolute left-0 right-0 h-full overflow-visible animate-fade-in-dubky">
      <div className="relative w-full h-full">
        {dubky.map(([size, left, top, color, angle], i) => {
          return <Dubek mobile={mobile} key={JSON.stringify([size, left, top, color, angle, i])} left={(left + translateX) / w} top={top / h} initialAngle={angle} angleFreedom={0.1} size={size} color={color} />
        })}
      </div>
    </div>
  )
}

export default DubkovePozadie
